/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider } from "@material-ui/pickers";
import { el } from "date-fns/locale";
// import "moment/locale/el";
// import moment from 'moment';
// import MomentAdapter from "@material-ui/pickers/adapter/moment";

export default function App({ store, persistor, basename }) {

	// useEffect(() => {
	// 	moment.locale('el');
	// }, [])

	return (
		/* Provide Redux store */
		<Provider store={store} loading={<LayoutSplashScreen />}>
			{/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
			<PersistGate persistor={persistor}>
				{/* Add high level `Suspense` in case if was not handled inside the React tree. */}
				<React.Suspense fallback={<LayoutSplashScreen />}>
					{/* Override `basename` (e.g: `homepage` in `package.json`) */}
					<BrowserRouter basename={basename}>
						{/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
						<LastLocationProvider>
							{/* Provide Metronic theme overrides. */}
							<ThemeProvider>
								{/* Provide `react-intl` context synchronized with Redux state.  */}
								<I18nProvider>
									{/* Provide locale for datepickers */}
									<LocalizationProvider dateAdapter={DateFnsAdapter} locale={el}>
									{/* <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale="el"> */}
										{/* Render routes with provided `Layout`. */}
										<Routes />
									</LocalizationProvider>
								</I18nProvider>
							</ThemeProvider>
						</LastLocationProvider>
					</BrowserRouter>
				</React.Suspense>
			</PersistGate>
		</Provider>
	);
}
