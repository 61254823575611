import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import { ResetPassword } from './ResetPassword';
import './auth.scss';

export default function AuthPage() {
  return (
      <>
        <div className="kt-grid kt-grid--ver kt-grid--root">
          <div
              id="kt_login"
              className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
          >
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
              <div
                  className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl("/media/bg/background-fullhd.png")})`
                  }}
              >
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver auth-logo">
                  <div className="kt-grid__item kt-grid__item--middle kt-login-info-grid">
                    <img alt="Logo" src={toAbsoluteUrl("/media/logos/vasileiou-logo-square.png")} style={{ marginBottom: '20px' }} />
                    <h3 className="kt-login__title">Βασιλείου Πατρογoνικές Όρνιθες</h3>
                    <h4 className="kt-login__subtitle">
                      Εφαρμογή διαχείρισης και παρακολούθησης
                    </h4>
                  </div>
                </div>
              </div>

              <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">

				{/* AUTH - ROUTING */}
                <Switch>
                  <Route path="/auth/login" component={Login} />
                  <Route path="/auth/registration" component={Registration} />
                  <Route path="/auth/forgot-password" component={ForgotPassword} />
                  <Route path="/auth/reset-password/:token" component={ResetPassword} />
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
