import axios from "axios";
import { config } from '../../config';

const BASE_URL = config.BASE_URL;
const ENDPOINT_URL = "dashboard/";

export function getLastDailyRecords() {
	return axios.get(`${BASE_URL}${ENDPOINT_URL}last-daily-records`);
}

export function getByLotName(lotNameId) {
	return axios.get(`${BASE_URL}${ENDPOINT_URL}by-lot-name/${lotNameId}`);
}