import React, { useState, useEffect } from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Save from 'react-feather/dist/icons/save';
import IconButton from '@material-ui/core/IconButton';

import { Portlet, PortletBody } from "../../partials/content/Portlet";
import { useConfirmation } from '../../partials/dialogs/ConfirmDialogService';
import * as dashboardAPI from '../../api/dashboard';
import * as lotNamesAPI from '../../api/lotNames';
import * as preferencesAPI from '../../api/preferences';

import './dashboard.scss';

export default function Dashboard() {

	const [dailyRecords, setDailyRecords] = useState([]);
	const [incomeSum, setIncomeSum] = useState('');
	const [incomeNitsiakosSum, setIncomeNitsiakosSum] = useState('');
	const [expenseSum, setExpenseSum] = useState('');
	const [expenseNitsiakosSum, setExpenseNitsiakosSum] = useState('');
	const [collectionSum, setCollectionSum] = useState('');
	const [loadingLotNames, setLoadingLotNames] = useState(true);
	const [selLotName, setSelLotName] = useState('');
	const [lotNames, setLotNames] = useState([]);
	const [error, setError] = useState(false);
	const [noPreference, setNoPreference] = useState(false);

	// Initializing the custom confirmation dialog instance
	const dialogConfirmation = useConfirmation();

	useEffect(() => {
		fetchLotNames();
		fetchPreferences();
	}, [])

	useEffect(() => {
		if (selLotName && selLotName !== '') {
			dashboardAPI.getByLotName(selLotName)
			.then(res => {
				setDailyRecords(res.data.dailyRecordsSummary);
				setExpenseSum(res.data.expenseSum);
				setIncomeSum(res.data.incomeSum);
				setCollectionSum(res.data.collectionSum);
				setIncomeNitsiakosSum(res.data.incomeNitsiakosSum);
				setExpenseNitsiakosSum(res.data.expenseNitsiakosSum);
			}).catch(err => {
				console.error('Error while getting daily records data for the selected lot name', err);
				setError(true);
			});
		}
	}, [selLotName])

	useEffect(() => {
		if (noPreference && !loadingLotNames) {
			// Getting the last daily records because the user has no preference stored
			dashboardAPI.getLastDailyRecords()
			.then(res => {
				setDailyRecords(res.data.dailyRecordsSummary);
				setExpenseSum(res.data.expenseSum);
				setIncomeSum(res.data.incomeSum);
				setCollectionSum(res.data.collectionSum);
				setIncomeNitsiakosSum(res.data.incomeNitsiakosSum);
				setExpenseNitsiakosSum(res.data.expenseNitsiakosSum);
			}).catch(err => {
				console.error('Error while getting last daily records data', err);
				setError(true);
			})
		}
	}, [loadingLotNames, noPreference]);

	const fetchLotNames = () => {
		lotNamesAPI.getAll()
		.then(res => {
			setLotNames(res.data);
			setLoadingLotNames(false);
		})
		.catch(err => {
			console.error(err);
			setError(true);
			if (err.response.status === 401) {
				setTimeout(() => {
					fetchLotNames();
				}, 1000);
			}
		})
	}

	const fetchPreferences = () => {
		preferencesAPI.getPreference('dashboardLotName')
		.then(res => {
			console.log('user preference for selected lot name', res.data);
			setSelLotName(res.data.value);
		})
		.catch(err => {
			if (err.response.status === 404) {
				// we do not have any preference stored. We should get the latest.
				setNoPreference(true);
			} else if (err.response.status === 401) {
				setTimeout(() => {
					fetchPreferences();
				}, 1000);
			} else {
				setError(true);
				console.error('Error while getting preferences', err);
			}
		})
	}

	const savePreference = () => {
		if (selLotName === '') {
			dialogConfirmation({
				variant: 'info',
				type: 'warning',
				catchOnCancel: false,
				title: 'Προσοχή!',
				description: 'Θα πρέπει να επιλέξετε μια εκτροφή για να αποθηκεύσετε την προτίμηση σας.'
			})
			return;
		}

		preferencesAPI.updatePreference('dashboardLotName', selLotName)
		.then(res => {
			dialogConfirmation({
				variant: 'info',
				type: 'success',
				catchOnCancel: false,
				title: 'Τέλεια!',
				description: 'Η προτίμηση σας αποθηκεύτηκε επιτυχώς.'
			})
		})
		.catch(err => {
			dialogConfirmation({
				variant: 'info',
				type: 'error',
				catchOnCancel: false,
				title: 'Σφάλμα!',
				description: 'Προέκυψε σφάλμα κατά την αποθήκευση της προτίμησης σας. Παρακαλούμε προσπαθήστε ξανά και αν το πρόβλημα παραμείνει επικοινωνήστε με την υποστήριξη.'
			})
		});
	}

	const renderDate = (date) => { return new Date(date).toLocaleDateString('el-GR'); }

	const formatMoney = (amount) => { return isNaN(amount) ? '' : Number(amount).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '€';}

	return (
		<div className="dashboard">
			<div id="kt_subheader" className="kt-subheader kt-grid__item">
				<h3 className="kt-subheader__title">Σύνοψη Τελευταίας Εκτροφής</h3>
				<div className="filters">
					<Select
						value={selLotName}
						// input={ <OutlinedInput placeholder="Επιλέξτε Εκτροφή" /> }
						onChange={e => {setSelLotName(e.target.value)}}
						className="subheader-filter"
						displayEmpty
						style={{ marginRight: 10, height: 50, minWidth: 300 }}
					>
						<MenuItem value="" key="selectLotName" disabled>Επιλέξτε Εκτροφή</MenuItem>
						{!loadingLotNames && lotNames && lotNames.map(lotName => {
							return (
								<MenuItem key={lotName.id} value={lotName.id}>{lotName.name}</MenuItem>
							)
						})}
					</Select>
					<IconButton className="subheader-icon-button lightblue-button" aria-label="Αποθήκευση Προτίμησης" onClick={() => {savePreference()}}>
						<Save />
					</IconButton>
				</div>
			</div>
			<div className="row">
				{
					dailyRecords && dailyRecords.map((d, index) => (
						<div key={'daily'+index} className="col-xl-4">
							<Portlet>
								<PortletBody>
									<div className="kt-widget1">
										<div className="kt-widget1__item d-flex flex-column">
											<div className="record-title-section">
												<div className="record-title">{d.chamber}</div>
												<div className="record-title">{d.lot}</div>
											</div>
											<div className="record-date">Τελευταία Καταγραφή: {renderDate(d.date)}</div>
										</div>

										<div className="kt-widget1__item">
											<div className="kt-widget1__info">
												<h3 className="number-title">Ημέρες</h3>
											</div>
											<span className="kt-widget1__number kt-font-brand">{d.days}</span>
										</div>

										<div className="kt-widget1__item">
											<div className="kt-widget1__info">
											<h3 className="number-title">Εβδομάδες</h3>
											</div>
											<span className="kt-widget1__number kt-font-brand">{d.weeks}</span>
										</div>

										<div className="kt-widget1__item">
											<div className="kt-widget1__info">
											<h3 className="number-title">Πραγματικός Αριθμός Θηλυκών</h3>
											</div>
											<span className="kt-widget1__number kt-font-brand">{d.realCountF}</span>
										</div>

										<div className="kt-widget1__item">
											<div className="kt-widget1__info">
											<h3 className="number-title">Πραγματικός Αριθμός Αρσενικών</h3>
											</div>
											<span className="kt-widget1__number kt-font-brand">{d.realCountM}</span>
										</div>

										<div className="kt-widget1__item">
											<div className="kt-widget1__info">
											<h3 className="number-title">Ποσοστό Συνολικών Αυγών</h3>
											</div>
											<span className="kt-widget1__number kt-font-brand">{d.percTotalEggs}%</span>
										</div>

										<div className="kt-widget1__item">
											<div className="kt-widget1__info">
											<h3 className="number-title">Ποσοστό Εκκολάψιμων Αυγών</h3>
											</div>
											<span className="kt-widget1__number kt-font-brand">{d.percHatchableEggs}%</span>
										</div>
									</div>
								</PortletBody>
							</Portlet>
						</div>
					))
				}
			</div>

			<div className="row">
				<div className="col-12">
					<h4>Υπόλοιπο με Νιτσιάκο</h4>
				</div>
			</div>

			<div className="row">
				<div className="col-xl-3">
					<Portlet>
						<PortletBody>
							<div className="kt-widget1">
								<div className="kt-widget1__item d-flex flex-column">
									<div className="record-title-section">
										<div className="record-title">Έσοδα</div>
										<div className="record-title">{formatMoney(incomeNitsiakosSum)}</div>
									</div>
								</div>								
							</div>
						</PortletBody>
					</Portlet>
				</div>

				<div className="col-xl-3">
					<Portlet>
						<PortletBody>
							<div className="kt-widget1">
								<div className="kt-widget1__item d-flex flex-column">
									<div className="record-title-section">
										<div className="record-title">Εισπράξεις</div>
										<div className="record-title">{formatMoney(collectionSum)}</div>
									</div>
								</div>								
							</div>
						</PortletBody>
					</Portlet>
				</div>

				<div className="col-xl-3">
					<Portlet>
						<PortletBody>
							<div className="kt-widget1">
								<div className="kt-widget1__item d-flex flex-column">
									<div className="record-title-section">
										<div className="record-title">Εξοδα</div>
										<div className="record-title">{formatMoney(expenseNitsiakosSum)}</div>
									</div>
								</div>								
							</div>
						</PortletBody>
					</Portlet>
				</div>

				<div className="col-xl-3">
					<Portlet>
						<PortletBody>
							<div className="kt-widget1">
								<div className="kt-widget1__item d-flex flex-column">
									<div className="record-title-section">
										<div className="record-title">Υπόλοιπο</div>
										<div className="record-title">{formatMoney(incomeNitsiakosSum - collectionSum - expenseNitsiakosSum)}</div>
									</div>
								</div>								
							</div>
						</PortletBody>
					</Portlet>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<h4>Λογιστικό Υπόλοιπο</h4>
				</div>
			</div>

			<div className="row">
				<div className="col-xl-4">
					<Portlet>
						<PortletBody>
							<div className="kt-widget1">
								<div className="kt-widget1__item d-flex flex-column">
									<div className="record-title-section">
										<div className="record-title">Έσοδα</div>
										<div className="record-title">{formatMoney(incomeSum)}</div>
									</div>
								</div>								
							</div>
						</PortletBody>
					</Portlet>
				</div>

				<div className="col-xl-4">
					<Portlet>
						<PortletBody>
							<div className="kt-widget1">
								<div className="kt-widget1__item d-flex flex-column">
									<div className="record-title-section">
										<div className="record-title">Εξοδα</div>
										<div className="record-title">{formatMoney(expenseSum)}</div>
									</div>
								</div>								
							</div>
						</PortletBody>
					</Portlet>
				</div>

				<div className="col-xl-4">
					<Portlet>
						<PortletBody>
							<div className="kt-widget1">
								<div className="kt-widget1__item d-flex flex-column">
									<div className="record-title-section">
										<div className="record-title">Υπόλοιπο</div>
										<div className="record-title">{formatMoney(incomeSum - expenseSum)}</div>
									</div>
								</div>								
							</div>
						</PortletBody>
					</Portlet>
				</div>
			</div>
		</div>
	);
}
