import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { connect } from 'react-redux';

import Dashboard from './Dashboard';
import { LayoutSplashScreen } from '../../../_metronic';
import * as AuthAPI from '../../api/auth';
import * as auth from '../../store/ducks/auth.duck';
import { ConfirmServiceProvider } from '../../partials/dialogs/ConfirmDialogService';

const IncomePage = lazy(() => import('./income/IncomePage'));
const ExpensePage = lazy(() => import('./expense/ExpensePage'));
const LotPage = lazy(() => import('./lot/LotPage'));
const SettingsPage = lazy(() => import('./settings/SettingsPage'));
const DailyRecordPage = lazy(() => import('./dailyRecord/DailyRecordPage'));
const SlaughterPage = lazy(() => import('./slaughter/SlaughterPage'));
const CollectionPage = lazy(() => import('./collection/CollectionPage'));
const ReportsPage = lazy(() => import('./reports/ReportsPage'));

class HomePage extends React.Component {

	componentDidMount() {
		// handle the refresh of the token
		console.log('The token is', jwt_decode(this.props.token));
		console.log('the refresh token is', this.props.refreshToken);
		// console.log('refresh token', jwt_decode(this.props.refreshToken));

		this.refreshTheToken();
		
	}

	refreshTheToken() {
		let timeToExpireToken = jwt_decode(this.props.token).exp - new Date().getTime()/1000;
		let timeToExpireRefreshToken = jwt_decode(this.props.refreshToken).exp - new Date().getTime()/1000;

		console.log('[AUTH - TOKEN VALID FOR]', timeToExpireToken);
		console.log('[AUTH - REFRESH TOKEN VALID FOR]', timeToExpireRefreshToken);

		setTimeout(() => {

			// Checking if the Refresh Token will expire
			if (timeToExpireRefreshToken > 10) {
				
				console.log('[AUTH - REFRESHING TOKEN]');

				AuthAPI.refreshToken(this.props.refreshToken, jwt_decode(this.props.refreshToken).user_id)
					.then(res => {
						this.props.refreshTokenAction(res.data.token, res.data.refreshToken);
						console.log('[AUTH - TOKEN REFRESHED]');
						
						// Recursive calling the function to repeat the refresh
						this.refreshTheToken();
					}).catch(err => {
						console.log('[AUTH - ERROR WHILE REFRESHING THE TOKEN]', err);
						// The refresh token attempt failed. Must redirect to login
						this.props.logoutAction();
					})	

			} else {
				console.log('[AUTH - REFRESH TOKEN EXPIRED]', timeToExpireToken);

				// Redirect to login
				this.props.logoutAction();
			}

			
		},(timeToExpireToken - 10)*1000) // refresh it 10 seconds before expiration
	}

	render() {
		return (
			<Suspense fallback={<LayoutSplashScreen />}>
				<ConfirmServiceProvider>
					<Switch>
						{
							/* Redirect from root URL to /dashboard. */
							<Redirect exact from='/' to='/dashboard' />
						}
						<Route path='/dashboard' component={Dashboard} />
						<Route path='/income' component={IncomePage} />
						<Route path='/expense' component={ExpensePage} />
						<Route path='/daily-record' component={DailyRecordPage} />
						<Route path='/lot' component={LotPage} />
						<Route path='/collection' component={CollectionPage} />
						<Route path='/slaughter' component={SlaughterPage} />
						<Route path='/reports' component={ReportsPage} />
						<Route path='/settings' component={SettingsPage} />
						<Redirect to='/error' />
					</Switch>
				</ConfirmServiceProvider>
			</Suspense>
		);
	}
}

const mapStateToProps = ({ auth: { token, refreshToken } }) => ({
	token, refreshToken
});

export default connect(mapStateToProps, auth.actions)(HomePage);
