import axios from "axios";
import { config } from '../../config';

const BASE_URL = config.BASE_URL;
const ENDPOINT_URL = "lot-names/";

export function getAll() {
	return axios.get(`${BASE_URL}${ENDPOINT_URL}`);
}

export function save(name) {
	return axios.post(`${BASE_URL}${ENDPOINT_URL}`, { name });
}

export function update(id, name) {
	return axios.patch(`${BASE_URL}${ENDPOINT_URL}${id}`, { name });
}

export function get(id) {
	return axios.get(`${BASE_URL}${ENDPOINT_URL}${id}`);
}

export function remove(id) {
	return axios.delete(`${BASE_URL}${ENDPOINT_URL}${id}`);
}
